import {extend} from 'lodash';
import {EnvironmentBaseService} from "src/environments/environment-base.service";


export const environment = extend(EnvironmentBaseService, {
  siteenv: 'uat',
  listDataS3Bucket: 'skf-list-data-uat',
  APIEndpoint: 'https://uatappsapi.skfilluminate.net',
  APIEndPointImportAllocation:'https://0oic3q4ob8.execute-api.us-east-1.amazonaws.com/Stage',
  APIEndpointMariaDB: 'https://q3ylau6aph.execute-api.us-east-1.amazonaws.com/Stage',
  APISnowflakeEndpoint: 'https://of2734zd00.execute-api.us-east-1.amazonaws.com/uat',
  loginurl: 'https://appsuat-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=3heeau7djsotkeeip28j3pl3kj&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://appsuat1.skfilluminate.net',

});

