
export var EnvironmentBaseService = {

 // public environment = {
  production: false,
  confirm: {
    email: '',
    password: ''
  },

  pageAccessErrorMessage: 'YOU DO NOT HAVE PERMISSION TO ACCESS THIS PAGE. ',
  dataErrorMessage: 'There was an error while processing your request, please contact Administrator.',
  saveMessage: 'Your Changes Are Saved Successfully',
  dataSaveError: 'There was an error and your data was not saved.',
  dataRetrieveError: 'There was an error while fetching data.',
  deleteMessage: 'Are you sure you want to delete this item?',
  caseFormErrorMessage1: 'Activity Comment cannot be blank',
  illuminateAppURL: 'https://bi.skfilluminate.net/azure/sense/app/9884cc70-157f-4c3e-95f5-fc39f6c83644/sheet/4485db8e-b913-4ec1-9e22-91a49ef83d7e/state/analysis',
  illuminateMainAppURL: 'https://bi.skfilluminate.net/azure/hub',
  applicationAccessError: 'You do not have access to the application: ',
//  siteenv: 'prod',
 // listDataS3Bucket: 'skf-list-data-prod',
  pageSize: 10,
  TeamRoomMember: 'UX8578,KW3257',
  CXTeamMembers: 'UX8578,WC2167,ET4319,',
  impersonateUsers: 'UX8578,KW3257,YH2418,HG0968,SC6786,JY7928,Algoworks2,Algoworks1',
  callStatusWarning:'"You must enter a Root Cause Analysis for “Spoke to Customer” status"',
  reasonWhyWarning:'You must enter Reason for "Did Not Call/Re-Opened" Status.',
  fileUploadS3Bucket: 'skf-amer-bi-dev',
  webAppFilesS3Bucket: 'skf-upload-files-web-apps',
  applicationDetails:
  {
    'adam': {'applicationName': 'ADAM', 'logo': 'adam-favicon-scaled.png', 'hoverIcon': '/assets/ADAM-logo-2.png'},
    'resource-readiness': {'applicationName': 'Resource Readiness', 'logo': 'resource-readiness-logo.png', 'hoverIcon': '/assets/resource-readiness-logo.png'},
    // 'forecast-file-upload': {'applicationName': 'Forecast File Upload', 'fileType': 'ForecastOutlook', 'appNameFileType': 'Forecast', 'logo': 'forecast-logo.png', 'hoverIcon': '/assets/forecast-logo.png'},
    'file-upload-utility': {'applicationName': 'File Upload Utility', 'fileType': 'ForecastOutlook', 'appNameFileType': 'Forecast', 'logo': 'forecast-logo.png', 'hoverIcon': '/assets/forecast-logo.png'},
    'on-demand-quoting': {'applicationName': 'On Demand Quoting', 's3BucketName': 'lingua-on-demand-quoting-module', 'AMI':'ami-03fd870a2ca39046c', 'logo': 'on-demand-quote-logo.png', 'hoverIcon': '/assets/on-demand-quote-logo.png'},
    'customer-feedback-survey': {'applicationName': 'Customer Feedback Survey', 'logo': 'customer-feedback-survey-logo.png', 'hoverIcon': '/assets/customer-feedback-survey-logo.png'},
    'application-security': {'applicationName': 'Application Security', 'logo': 'app-security-logo.png', 'hoverIcon': '/assets/app-security-logo.png'},
    'application-security-external-apps': {'applicationName': 'Application Security External Apps', 'logo': 'appSecMarioDBLogo.png', 'hoverIcon': '/assets/app-security-logo.png'},
    'skf-usa-north-america-facilities-team': {'applicationName': 'SKF USA North America Facilities Team', 'logo': 'snaft-logo.png', 'hoverIcon': '/assets/snaft-logo.png'},
    'elevate': {'applicationName': 'Elevate', 'logo': 'elevate-logo.png', 'hoverIcon': '/assets/elevate-logo.png'},
    'compass': {'applicationName': 'Compass','logo': 'compass.png', 'hoverIcon': '/assets/elevate-logo.png'},
    'customer-solution':{'applicationName': 'Customer Solution', 'logo': 'customer-solution3.png', 'hoverIcon': '/assets/customer-feedback-survey-logo.png'},
    'rep': {'applicationName': 'REP', 'logo': 'REP-SKF-Logo.png', 'hoverIcon': '/assets/REP-SKF-Logo.png'},
    'on-site': {'applicationName': 'On Site', 'logo': 'Sitecheckin.png', 'hoverIcon': '/assets/Sitecheckin.png'},
    'lotus-note': {'applicationName': 'Lotus Note', 's3BucketName': 'skf-upload-files-web-apps','logo': 'LotusNote3.png', 'hoverIcon': '/assets/LotusNote3.png'},
    'forecasting': {'applicationName': 'Forecasting','logo': 'forecast-SKF-Blue.png', 'hoverIcon': '/assets/forecast-SKF-Blue.png'},
    'forecasting-after-market': {'applicationName': 'Forecasting After Market','logo': 'Forecasting-AM-SKF-Blue.png', 'hoverIcon': '/assets/forecast-SKF-Blue.png'},
    'p2p': {'applicationName': 'P2P', 's3BucketName': 'skf-upload-files-web-apps','logo': 'P2P-blue.png', 'hoverIcon': '/assets/P2P-blue.png'},
    'application-suite-zipcode': {'applicationName': 'Application Suite Zipcode','logo': 'zip-xxl.png', 'hoverIcon': '/assets/forecast-SKF-Blue.png'},
    'customer-requests-and-shipping-instructions': {'applicationName': 'Customer Requests and Shipping Instructions', 's3BucketName': 'skf-upload-files-web-apps','logo': 'LotusNote3.png', 'hoverIcon': '/assets/LotusNote3.png'},
    'import-allocation': {'applicationName': 'Import Allocation', 's3BucketName': 'skf-upload-files-web-apps', 'freightRefOutputS3Key': 'ImportExpenseAllocation/FreightRefOutput/', 'logo': 'ImportAllocation.png', 'hoverIcon': '/assets/ImportAllocation.png'},

  },
    filterMessage:'You have selected both filters, only Family Code filter will be applied.',
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rKz883f2j",
    "aws_user_pools_web_client_id": "40shc0qfo9aj577mriab9mlj0t",
    "oauth": {
        "domain": "appsdev-skfilluminate.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/",
        "redirectSignOut": "http://localhost:4200/",
        "responseType": "code"
    },
 };




